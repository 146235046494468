<!--
 * @Author: zhouyajuan
 * @Date: 2020-10-13 15:43:29
 * @LastEditTime: 2020-12-25 13:56:45
 * @LastEditors: Please set LastEditors
 * @Description: 我的办理
 * @FilePath: /netHallOfficialAccounts/src/views/BusinessManagement/index.vue
-->
<template>
  <div class="businessManagement-page">
    <div class="businessManagement-header">
      <van-tabs v-model="businessStatus" @click="onClick" color="#1677FF" title-active-color="#1677FF">
        <van-tab title="全部" name=""></van-tab>
        <van-tab
          v-for="(item, index) in dictoryBycode_STATUS_CODE"
          :key="index"
          :name="item.code"
          :title="item.name"
        ></van-tab>
      </van-tabs>
    </div>
    <div :class="selectedFlag ? 'businessManagement-select' : 'businessManagement-selected'">
      <van-dropdown-menu active-color="#1677FF" :close-on-click-overlay="false">
        <van-dropdown-item :title="titleValue" ref="item">
          <drop-down-selection
            :currentValue="currentIndex"
            :dataValArr="businessTerms"
            @getSelectValue="getSelectValueInfo"
          />
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :disabled="true" v-if="dataInfoFlag">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="selectFeedbackHandling">
        <div class="businessManagement-content" v-if="dataInfoFlag">
          <ul>
            <li v-for="(item, index) in dataInfo" :key="index" @click="gotoDetailPage(item)">
              <div class="title-li">
                <div class="left-txt">
                  <i><img :src="contentIcon(item.status, item.progessStatusCode, item.progessStatus)" alt=""/></i>
                  <span>{{ item.progessName }}</span>
                </div>
                <em>{{ item.createdTime }}</em>
              </div>

              <div class="content-li">
                <span class="van-ellipsis">{{ item.businessName }}</span>
                <em class="van-ellipsis" v-if="item.processingDays !== undefined && item.statusName !== '已办结'">
                  {{ '预计' + item.processingDays + '个工作日' }}
                </em>
              </div>

              <!-- 待预审  -->
              <div class="content-li" v-if="item.progessStatusCode === 'nh_00_01_st'">
                <span class="van-ellipsis">{{ item.businessName }}</span>
                <em
                  class="van-ellipsis"
                  v-if="item.processingDays !== undefined"
                  style="line-height: 25px; margin-top: 10px"
                >
                  <i>剩余操作时间</i><br />
                  {{ item.processingDays + '个工作日' }}
                </em>
              </div>

              <div class="detail-li" @click="gotoDetailPage(item)">
                <span>查看详情</span>
                <van-icon name="arrow" />
              </div>
            </li>
          </ul>
        </div>
      </van-list>
    </van-pull-refresh>

    <no-data v-else />
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import NoData from '@/components/noData';
import DropDownSelection from '@/components/dropDownSelection';
import handle_First from '../../assets/icon/ic_blue_icon@2x.png';
import handle_Second from '../../assets/icon/ic_grey_icon@2x.png';
import handle_Third from '../../assets/icon/ic_red_notice_icon@2x.png';
import handle_Fourth from '../../assets/icon/ic_red_refuse@2x.png';
import handle_Fifth from '../../assets/icon/ic_green_icon@2x.png';
import handle_Sixth from '../../assets/icon/待预审@2x.png';
import { selectBusHandlingPage } from '@/api/business';
import { formatTime } from '@/utils/tools';
import { detailsRoute } from '@/utils/businessRoute';

export default {
  name: 'BusinessManagement',
  components: {
    NoData,
    DropDownSelection
  },
  data() {
    return {
      titleValue: '办理类型筛选',
      businessStatus: '',
      moduleCode: '',
      dataInfo: [],
      dataInfoFlag: true,
      size: 5,
      currentPage: 1,
      dataTotal: 0,
      currentIndex: 0,
      refreshing: false,
      loading: false,
      finished: false,
      selectedFlag: true // 反馈类型筛选的颜色控制
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.query && from.query.businessStatus) {
        vm.businessStatus = from.query.businessStatus == 'all' ? '' : from.query.businessStatus;
        vm.currentIndex = Number(from.query.currentIndex);
        vm.moduleCode = from.query.moduleCode;
        vm.titleValue = from.query.isSelected == 'true' ? vm.businessTerms[vm.currentIndex].name : '办理类型筛选';
        vm.selectedFlag = from.query.isSelected == 'true' ? false : true;
      }
    });
  },
  computed: {
    ...mapState('dict', {
      dictory: (state) => state.dictory,
      dictoryBycode_STATUS_CODE: (state) => state.dictoryBycode_STATUS_CODE,
      businessManagement: (state) => state.businessManagement
    }),
    businessTerms() {
      if (this.dictory) {
        let data = this.dictory && this.dictory[this.businessManagement] && this.dictory[this.businessManagement].child;
        let reomveList = [
          '0020',
          '0002',
          '0003',
          '0009',
          '0010',
          '0011',
          '0012',
          '0008',
          '0005',
          '0006',
          '0007',
          '0013',
          '0014',
          '0016'
        ];
        data = data.filter((item) => {
          return !reomveList.includes(item.code);
        });

        let item = [{ child: [], code: '', name: '全部类型', value: '' }];
        let newdata = item.concat(data);
        return newdata;
      }
      return null;
    },
    contentIcon() {
      return function(val, subclass, status) {
        if (val === 'nh_00') {
          /* 办理中 */
          if (status === 'nh_00_01_st') {
            return handle_Sixth;
          } else {
            return handle_First;
          }
        } else if (val === 'nh_01') {
          /* 已办结 */
          return handle_Fifth;
        } else if (val === 'nh_02') {
          /* 已拒绝 */
          return handle_Fourth;
        } else if (val === 'nh_03') {
          /* 已取消 */
          return handle_Second;
        } else if (subclass === 'nh_00_05') {
          /* 补交资料 */
          return handle_Third;
        }
      };
    }
  },
  mounted() {},
  methods: {
    onClick(name, title) {
      this.dataTotal = 0;
      this.currentPage = 1;
      this.dataInfo = [];

      this.selectFeedbackHandling();
    },
    // 选择类型
    getSelectValueInfo(value, index) {
      if (value !== '全部类型') {
        this.businessTerms.map((item) => {
          if (item.name === value) {
            this.moduleCode = item.code;
          }
        });
      } else {
        this.moduleCode = '';
      }
      this.selectedFlag = false;
      this.currentIndex = index;
      this.titleValue = value;
      this.$refs.item.toggle();
      this.currentPage = 1;
      this.dataInfo = [];
      this.selectFeedbackHandling();
    },
    // 获取页面数据
    async selectFeedbackHandling() {
      let arg = {
        treatState: this.businessStatus,
        businessCode: this.moduleCode,
        page: {
          size: this.size,
          current: this.currentPage
        }
      };
      const { status, resultData } = await selectBusHandlingPage(arg);
      if (status === 'complete') {
        if (resultData.records.length > 0) {
          this.dataInfoFlag = true;
          resultData.records.map((item) => {
            item.time = formatTime(item.progessCreateTime);
          });
          // 分页
          this.dataTotal = resultData.total;
          this.dataInfo = this.dataInfo.concat(resultData.records);
          this.currentPage++;
          // 没有更多数据
          if (this.dataInfo.length >= Number(this.dataTotal)) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        } else {
          this.dataInfo = [];
          this.dataInfoFlag = false;
          this.finished = true;
          this.dataInfoFlag = false;
        }
      } else {
        this.finished = false;
      }
      this.loading = false;
    },
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1;
      this.dataTotal = 0;
      this.dataInfo = [];
      this.typeAllList = [];
      this.tabAllList = [];
      this.finished = false;
      this.selectFeedbackHandling();
      this.loading = false;
    },
    // 查看详情
    async gotoDetailPage(obj) {
      let data = {
        businessState: 'see',
        businessCode: obj.businessCode,
        moduleCode: obj.moduleCode,
        busHandlingId: obj.busHandlingId,
        moduleName: obj.businessName
      };
      await store.dispatch('user/setBusinessNews', data);
      let pathDetail = detailsRoute(obj.businessCode);
      this.$router.push({
        path: pathDetail,
        query: {
          businessStatus: this.businessStatus == '' ? 'all' : this.businessStatus,
          currentIndex: this.currentIndex,
          moduleCode: this.moduleCode,
          isSelected: this.titleValue === '办理类型筛选' ? false : true
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.businessManagement-page {
  .businessManagement-select {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 10px 16px;
    font-size: 14px;
    background: #ffffff;
    /deep/.van-dropdown-menu__title {
      width: 110px;
      color: rgba(0, 0, 0, 0.65);
    }
    /deep/.van-dropdown-menu__bar {
      height: auto;
      background: transparent;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.65) rgba(0, 0, 0, 0.65);
    }
    /deep/.van-dropdown-menu__item {
      margin-right: 10px;
    }
  }
  .businessManagement-selected {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 10px 16px;
    font-size: 14px;
    background: #ffffff;
    /deep/.van-dropdown-menu__bar {
      height: auto;
      background: transparent;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title {
      width: 110px;
      color: #1677ff;
    }
    /deep/.van-dropdown-menu__title::after {
      border-color: transparent transparent #1677ff #1677ff;
    }
  }
  .businessManagement-content {
    ul {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 10px;
      li {
        margin-bottom: 16px;
        background: #ffffff;
        border-radius: 8px;
        padding-left: 16px;
        padding-right: 16px;
        .title-li {
          display: flex;
          justify-content: space-between;
          height: 48px;
          line-height: 48px;
          padding: 0;
          // border-bottom: 1px solid rgba(0,0,0,0.08);
          position: relative;
          i {
            font-style: normal;
            padding-right: 8px;
            img {
              width: 18px;
              height: 18px;
              vertical-align: middle;
            }
          }
          span {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            text-align: center;
          }
          em {
            font-style: normal;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
            text-align: center;
          }
        }
        .title-li::after {
          position: absolute;
          box-sizing: border-box;
          content: ' ';
          pointer-events: none;
          right: 0px;
          bottom: 0;
          left: 0px;
          border-bottom: 1px solid #ebedf0;
          -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
        }
        .content-li {
          height: 52px;
          line-height: 52px;
          display: flex;
          justify-content: space-between;
          // border-bottom: 1px solid rgba(0,0,0,0.08);
          position: relative;
          span {
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 700;
            width: 67%;
          }
          em {
            font-style: normal;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            width: 33%;

            i {
              font-style: normal;
            }
          }
          .rightInfo {
            margin-top: 4px;
            p {
              margin: 0px;
              font-family: PingFangSC-Regular;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.65);
              text-align: right;
              line-height: 22px;
            }
          }
        }
        .content-li::after {
          position: absolute;
          box-sizing: border-box;
          content: ' ';
          pointer-events: none;
          right: 0px;
          bottom: 0;
          left: 0px;
          border-bottom: 1px solid #ebedf0;
          -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
        }
        .detail-li {
          height: 49px;
          line-height: 49px;
          display: flex;
          justify-content: space-between;
          span {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
          }
          /deep/.van-icon {
            line-height: 49px;
            color: #969799;
          }
        }
      }
    }
  }
}
</style>
