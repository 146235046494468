<!--
 * @Author: zhouyajuan
 * @Date: 2020-10-15 08:23:46
 * @LastEditTime: 2021-04-09 15:35:32
 * @LastEditors: yangliao
 * @Description: 下拉选择内容
 * @FilePath: /netHallOfficialAccounts/src/components/dropDownSelection.vue
-->
<template>
    <div>
        <div class="content mgrA">
            <ul>
                <li
                    v-for="(item, index) in dataValArr"
                    :key="index"
                    @click="selectSpan(index)"
                    :class="currentValue === index ? 'currentSpan' : ''"
                >
                    <span>{{item.name}}</span>
                    <div class="triangle" v-if="currentValue === index">
                        <i><van-icon name="success" /></i>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'dropDownSelection',
    props: {
        dataValArr: {
            type: Array,
            require: true,
            default: () => {
                return [];
            }
        },
        currentValue: {
            type: Number,
            require: true,
            default: () => {
                return 0;
            }
        }
    },
    data() {
        return {
            // currentValue: 0
        }
    },
    mounted() {

    },
    methods: {
        // 选择年份
        selectSpan(index) {
            // console.log('Value', this.dataValArr[index].name)
            // this.currentValue = index;
            this.$emit('getSelectValue', this.dataValArr[index].name, index)
        },
    }
}
</script>

<style lang="less" scoped>
// 下拉年份
.content {
    padding-top: 5px;
    padding-bottom: 10px;
    // li标签
    ul {
        li {
            width: calc(100% / 3 - 17px);
            display: inline-block;
            text-align: center;
            // border: 1px solid #eeeeee;
            background-color: #F5F5F5;
            border-radius: 3px;
            margin-right: 15px;
            margin-top: 15px;
            position: relative;
            overflow: hidden;
            span {
                display: inline-block;
                height: 32px;
                line-height: 32px;
                font-family: PingFangSC-Medium;
                font-size: 14px;
                color: #333333;
                text-align: center;
            }
            // 选中样式
            .triangle {
                width: 30px;
                height: 30px;
                background: #1677FF;
                position: absolute;
                bottom: -15px;
                right: -15px;
                transform: rotate(45deg);
                i {
                    position: absolute;
                    bottom: 5px;
                    right: 5px;
                    display: block;
                    width: 18px;
                    text-align: center;
                    color: #ffffff;
                    transform: rotate(-25deg);
                }
            }
            .triangle::after {
                display: inline-block;
                content: '';
                width: 30px;
                height: 35px;
                background: #ffffff;
                transform: rotate(-45deg);
                position: absolute;
                bottom: 18px;
                right: 0px;
                z-index: 20;
            }
        }
        // 选中的样式
        .currentSpan {
            color: #1677FF;
            background: #E7F1FF;
            // border: 1px solid #E7F1FF;
        }
    }
}
.mgrA {
    margin-left: 16px;
}
</style>
