<!--
 * @Author: zhouyajuan
 * @Date: 2020-10-14 15:43:44
 * @LastEditTime: 2020-11-17 20:17:53
 * @LastEditors: Please set LastEditors
 * @Description: 没有数据
 * @FilePath: \nethallofficialaccounts\src\components\noData.vue
-->
<template>
    <div>
        <div class="noData">
            <div></div>
            <p>未找到相关结果</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NoData',
    data() {
        return {
        }
    }
}
</script>

<style lang="less" scoped>
    // 没有数据
    .noData {
        text-align: center;
        margin-top: 20%;
        em {
            font-style: normal;
            display: inline-block;
            width: 140px;
            height: 140px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        div {
            width: 140px;
            height: 140px;
            background: url('../assets/icon/pic_noData@2x.png') no-repeat 0 0;
            background-size: cover;
            margin: 0 auto;
        }
        p {
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #A2B0C6;
            text-align: center;
        }
    }
</style>